// @import "./styles/custom-themes/primary-theme.scss";
@use '@angular/material' as mat;
@include mat.core();
@import "./theme";
@import './app/directives/mat-table-responsive/mat-table-responsive.directive.scss';

html,
body {
  // background-color: #f9f9f9;
  font-family: Roboto, sans-serif !important;
  height: 100%;
  margin: 0;
  overflow:hidden;
}

.spacer {
  flex: 1 1 auto;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1%;
  column-gap: 1%;
  height: 100%;
}

span.icon-label {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}

mat-card mat-card-header {
  margin-bottom: 1em;
}
* {
  font-family: Roboto;
}

.flex-layout-row {
  & > * {
    width: 100%;
  }
  display: flex;
  column-gap: 5px;
}

.align-items-center {
  align-items: center;
}

.form-fields-layout {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  .mat-mdc-form-field {
    width: 100%;
}
}
.form-layout {
  margin: auto;
  width: 600px;
}
.tb-split-menu-menu + * .cdk-overlay-pane {
  margin-top: 5px;
}

hr {
  display: block;
  margin: 10px 0 10px 0;
  width: 100%
}

div.mat-mdc-form-field-icon-suffix {
  padding-right: 4px;
}

div.mat-mdc-form-field-icon-suffix, div.mat-mdc-form-field-icon-prefix {
  & > span,
  & > mat-icon {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  span {
    font-weight: 500;
  }
  & > button {
    // margin-right: 10px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-layout {
  margin: auto;
  // width: 600px;
  // margin-top: 50px;
}

$snackbar-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);


  .mat-mdc-snack-bar-container {
    .mat-mdc-snack-bar-label {
      padding: 0;
    }
  }

td,
th {
  white-space: nowrap;
  &.numeric {
    text-align: right;
  }
}

form.form-fields-layout .mdc-card__actions {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  .mdc-button {
      width: 100%;
  }
}

.mat-mdc-card.mdc-card {
  .mat-mdc-card-header {
    div.mat-mdc-card-header-text {
        width: 100%;
    }
  }
}

itfg-message div.message-container {
  padding: 16px;
  padding-top: 0;
}

div.mat-stepper-vertical-padding {
  mat-stepper.mat-stepper-vertical {
    div.mat-vertical-content-container {
      div.mat-vertical-stepper-content {
        div.mat-vertical-content {
          padding: 8px 24px 24px 24px;
        }
      }
    }
  }
}

.mat-step-header .mat-step-icon-state-done {
  background-color: green;
}

@media (max-width: 800px) {
  .form-layout {
    width: 100%;
  }
}

div.mat-step {
  div.mat-vertical-content-container {
    margin-left: 16px;
  }
  .mat-vertical-stepper-header {
    padding-left: 6px;
  }
}

.modal-dialog-title {
  font-size: 24px;
  font-weight: 400;
}

.mat-mdc-dialog-container [mat-dialog-subtitle] {
  display: block;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 0 1px;
  padding: var(--mat-dialog-headline-padding, 0 24px 9px);
  color: gray;
}