@use '@angular/material' as mat;
@use './app-theme';

$itfg-palette: (
  50: #fdeee3,
  100: #fbd6b8,
  200: #f8ba89,
  300: #f59e59,
  400: #f28a36,
  500: #f07512,
  600: #ee6d10,
  700: #ec620d,
  800: #e9580a,
  900: #e54505,
  A100: #ffffff,
  A200: #ffe2da,
  A400: #ffbba7,
  A700: #ffa88d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$theme-primary: mat.m2-define-palette($itfg-palette);
$theme-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$theme-warn: mat.m2-define-palette(mat.$m2-red-palette);
$theme: mat.m2-define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  typography: mat.m2-define-typography-config(),
));
$dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
));

// @include mat.core();
// @include mat.all-component-themes($theme);
// @include app-theme.theme($theme);

body.dark-theme {
  background: #2B2B2D;
  color: #CDD3D8;
  -webkit-font-smoothing: antialiased;
  // @include mat.all-component-colors($dark-theme);
}